@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: rgb(239,239,239);
}

h1, h2, h3, h4, h5, h6 {
  color: #1c4d82;
}

h1 {
  font-weight: 700;
}

h2 {
  font-size: 26px;
  font-weight: 600;
  color: #0c0f26;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
}
